const Enums = {
  values: {
    ROOT_TAG: 'root'
  },
  environment: {
    NODE_PRODUCTION: 'production',
    FIREBASE_LOCAL: 'firebase-local',
    FIREBASE_PROD: 'firebase-prod'
  },
  APP_IDS: {
    HOME: 'home'
  },
  APP_NAMES: {
    home: 'Home'
  },
  APP_PROFILE_TITLES: {},
  APP_VIEW_TITLES: {},
  VALUES_STRINGS: {
    COMMA: ',',
    EMPTY: '',
    SPACE: ' ',
    COLON: ':',
    ZERO: '0',
    NEW_LOWER_CASE: 'new',
    NEW_PROPER_CASE: 'New',
    APPLICATION_JSON: 'application/json',
    DATA: 'data',
    ADMINISTRATION: 'Administration',
    PORTAL_APPS: 'Portal Apps',
    PORTAL_MENU: 'Portal Menu',
    USER_PROFILE: 'userProfile',
    VIEW: 'view',
    FORM: 'form',
    ADD: 'add',
    DELETE: 'delete',
    UPDATE: 'update'
  },
  HEADERS: {
    API_KEY: 'api-key',
    RECORD_ID: 'record-id',
    MY_COURSES: 'my-courses',
    CURRENT_USER: 'current-user',
    SEARCH_VALUE: 'search-value',
    UPDATE_TYPE: 'update-type',
    CATEGORY: 'category'
  },
  QUERY_PARAMETERS: {
    OPEN_DOCUMENT: 'od'
  },
  TITLES: {
    COURSES: 'Home',
    ADMINISTRATION: 'Administration',
    CATEGORIES: 'Categories',
    PORTAL_MENU: 'Portal Menu',
    NEW_COURSE: 'New Course',
    EDIT_COURSE: 'Edit Course',
    OPEN_COURSE: 'Open Course'
  },
  KEYS: {
    COURSES: 'courses',
    ADMINISTRATION: 'administration',
    CATEGORIES: 'categories',
    NEW_COURSE: 'new_course',
    EDIT_COURSE: 'edit_course',
    OPEN_COURSE: 'open_course'
  },
  ROLES: {
    ADMIN: 'Admin'
  },
  ACTIONS: {
    ITEM_CLICK: 'ITEM_CLICK',
    LEFT_MENU_CLOSE: 'LEFT_MENU_CLOSE',
    LEFT_MENU_OPEN: 'LEFT_MENU_OPEN',
    RIGHT_MENU_CLOSE: 'RIGHT_MENU_CLOSE',
    RIGHT_MENU_OPEN: 'RIGHT_MENU_OPEN',
    TAB_CHANGE: 'TAB_CHANGE',
    TAB_CLOSE: 'TAB_CLOSE',
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    NEW_COURSE: 'NEW_COURSE',
    EDIT_COURSE: 'EDIT_COURSE',
    OPEN_COURSE: 'OPEN_COURSE',
    DELETE_COURSE: 'DELETE_COURSE',
    SET_COURSE: 'SET_COURSES',
    UPDATE_COURSE: 'UPDATE_COURSES',
    SET_INIT_CONFIG: 'SET_INIT_CONFIG',
    SET_USER: 'SET_USER',
    RESET_APP: 'RESET_APP'
  },
  REQ_TYPE: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
  },
  ENDPOINTS: {
    INIT: '/init',
    USER_LOGIN: '/user/login',
    COURSE_DATA: '/courses/data',
    CATEGORY_DATA: '/categories/data',
    COURSES_SEARCH: '/courses/search',
    FILES: '/files'
  },
  MESSAGES: {
    UNKNOWN_ERROR: 'An unknown error occurred. The Metability services may not be available',
    SIGN_IN: 'Sign in below to access the Portal',
    SIGN_OUT_CONFIRM: 'Are you sure you want to Sign Out?',
    DELETE_CONFIRM: 'Are you sure you want to delete this',
    SPECIFY_PREFIX: 'You can only specify up to',
    REQUIRED_COURSE_TITLE: 'Please provide a Course Title',
    REQUIRED_COURSE_DESCRIPTION: 'Please provide a Course Description',
    REQUIRED_COURSE_OVERVIEW: 'Please provide a Course Overview',
    REQUIRED_LEVEL_NAME: 'Please provide a Name for this level',
    REQUIRED_LESSON_TITLE: 'Please provide a Title',
    REQUIRED_LESSON_DESCRIPTION: 'Please provide a Description',
    REQUIRED_LESSON_LEVEL_ID: 'Please select a Level for this Lesson',
    REQUIRED_LESSON_SKILL_POINTS: 'Please provide Skill Points',
    REQUIRED_LESSON_SKILL_COINTS: 'Please provide Skill Coins',
    REQUIRED_LESSON_NEXT_LESSON: 'Please provide the Next Lesson',
    REQUIRED_LESSON_CONTENT: 'Please provide either Markdown Content, or a Video or Audio URL for this Lesson'
  },
  PLACEHOLDERS: {
    COURSE_TITLE: 'Provide a title for the course',
    COURSE_DESCRRIPTION: 'Provide a description for the course'
  },
  IMAGE_ALTS: {
    METABILITY_LOGO: 'Metability Logo'
  },
  COURSE_VALUES: {
    CATEGORY_LIMIT: 1,
    TAG_LIMIT: 10
  }
}

export default Enums
