import React from 'react'
import cn from 'classnames'
import { Result } from 'antd'
import styles from './style.module.sass'

import Icon from '-new/lib/icons-svg'

const LoginEmailSuccess = ({ onCancelOption }) => {
  const returnResultTitle = () => {
    return <h2 className={cn('h2', styles.title)}>Email Link Request Successful</h2>
  }

  const returnResultSubTitle = () => {
    return <div className={styles.text}>You should receive an Email Verification shortly. Please check your inbox.</div>
  }

  return (
    <Result
      status='success'
      title={returnResultTitle()}
      subTitle={returnResultSubTitle()}
      extra={[
        <button className={cn('button', styles.button)} onClick={onCancelOption}>
          <Icon name='home' size='24' />
          Back to Home
        </button>
      ]}
    />
  )
}

export default LoginEmailSuccess
