import React, { useCallback, useEffect, useReducer } from 'react'
import { useDropzone } from 'react-dropzone'
import { message, Table, Popconfirm } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { v1 } from 'agilite-utils/uuid'

import Theme from '../../styles/theme'

const CourseUploads = ({ onChangeImage, image }) => {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    fileList: []
  })

  useEffect(() => {
    try {
      const fileList = image?.name ? [image] : []
      setState({ fileList })
    } catch (e) {
      console.error(e)
    }
  }, [image])

  const onDrop = useCallback((acceptedFiles) => {
    // eslint-disable-next-line
    const reader = new FileReader()

    reader.onabort = () => message.warn('File upload aborted')
    reader.onerror = () => message.error('An error occured while reading the file, please try again.')

    reader.onload = async () => {
      try {
        const buffer = Buffer.from(reader.result)
        const base64 = buffer.toString('base64')
        const fileEntry = {
          name: acceptedFiles[0].name,
          data: base64
        }

        setState({ fileList: [fileEntry] })
        onChangeImage(fileEntry)
      } catch (error) {
        message.error(error)
      }
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      reader.readAsArrayBuffer(acceptedFiles[0])
    } else {
      message.error("Please select or drop a valid '.jpg' or '.png' image")
    }
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.jpg, .png', multiple: false })

  const uploadButton = (
    <div
      style={{
        fontSize: 15,
        color: Theme.custom.lightGray,
        padding: 10,
        border: `1px dashed ${Theme.custom.lightGray}`,
        textAlign: 'center'
      }}
    >
      <PlusOutlined />
      <div>Drag 'n' drop a Course Image here, or click to select an Image</div>
    </div>
  )

  const removeImage = (index) => {
    const tmpFileList = state.fileList.concat()
    tmpFileList.splice(index, 1)
    setState({ fileList: tmpFileList })
    onChangeImage(null)
  }

  return (
    <>
      {state.fileList.length < 1 ? (
        <div {...getRootProps()} style={{ marginBottom: 10, width: '50%' }}>
          <input {...getInputProps()} />
          {uploadButton}
        </div>
      ) : null}
      {state.fileList.length > 0 ? (
        <Table
          columns={[
            {
              title: 'Course Image',
              dataIndex: 'name',
              key: 'name',
              width: '95%',
              render: (text, record) => {
                return <>{record.name}</>
              }
            },
            {
              title: 'Actions',
              dataIndex: 'actions',
              key: 'actions',
              width: '5%',
              render: (text, record, index) => {
                return (
                  <div>
                    <span>
                      <Popconfirm
                        title='Are you sure you want to remove this image from the image list?'
                        okText='Yes'
                        cancelText='No'
                        onConfirm={() => removeImage(index)}
                      >
                        <DeleteOutlined style={{ color: Theme.twitterBootstrap.danger }} />
                      </Popconfirm>
                    </span>
                  </div>
                )
              }
            }
          ]}
          dataSource={state.fileList}
          bordered
          size='small'
          pagination={false}
          rowKey={() => v1()}
          style={{ width: '50%' }}
        />
      ) : null}
    </>
  )
}

export default CourseUploads
