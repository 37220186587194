import React from 'react'
import truncate from 'lodash/truncate'

import AgiliteReactState from '../config/agilite-react-config'
import Enums from '../utils/enums'
import { handleTabAdd } from '../utils/utilities'

// Components
import CourseListView from '../courses/components/course-list-view'
import CourseForm from '../courses/components/course-form'
import Categories from '../categories/components/categories'
import CustomMenu from './components/custom-menu'

const CoreReducer = (state = AgiliteReactState, action) => {
  let tmpMenuItems = null
  let tmpTabs = null
  let tmpValue = null
  let tabExists = false
  let tmpActiveKey = null

  switch (action.type) {
    case Enums.ACTIONS.ITEM_CLICK:
      tmpTabs = state.tabNavigation.tabs

      switch (action.key) {
        case Enums.KEYS.COURSES:
          tmpValue = {
            key: Enums.KEYS.COURSES,
            closeable: false,
            title: Enums.TITLES.COURSES,
            content: <CourseListView />
          }
          break
        case Enums.KEYS.CATEGORIES:
          tmpValue = {
            key: Enums.KEYS.CATEGORIES,
            closeable: true,
            title: Enums.TITLES.CATEGORIES,
            content: <Categories />
          }
          break
        default:
          break
      }

      if (tmpValue.key === Enums.KEYS.COURSES) {
        tabExists = true
      } else {
        tmpTabs.map((tab) => {
          if (tab.key === tmpValue.key) tabExists = true
          return null
        })
      }

      if (!tabExists) tmpTabs.push(tmpValue)

      return Object.assign({}, state, {
        ...state,
        tabNavigation: {
          ...state.tabNavigation,
          tabs: tmpTabs,
          activeKey: action.key
        },
        leftMenu: {
          ...state.leftMenu,
          visible: false
        },
        rightMenu: {
          ...state.rightMenu,
          visible: false
        }
      })
    case Enums.ACTIONS.TAB_CHANGE:
      return Object.assign({}, state, {
        ...state,
        tabNavigation: {
          ...state.tabNavigation,
          activeKey: action.key
        }
      })
    case Enums.ACTIONS.TAB_CLOSE:
      tmpTabs = state.tabNavigation.tabs.filter((tab) => tab.key !== action.key)

      if (tmpTabs.length > 0) {
        tmpActiveKey = tmpTabs[tmpTabs.length - 1].key
      } else {
        tmpActiveKey = Enums.KEYS.COURSES
      }

      return Object.assign({}, state, {
        ...state,
        tabNavigation: {
          ...state.tabNavigation,
          tabs: tmpTabs,
          activeKey: tmpActiveKey
        }
      })
    case Enums.ACTIONS.LEFT_MENU_OPEN:
    case Enums.ACTIONS.LEFT_MENU_CLOSE:
      return Object.assign({}, state, {
        ...state,
        leftMenu: {
          ...state.leftMenu,
          visible: !state.leftMenu.visible
        }
      })
    case Enums.ACTIONS.RIGHT_MENU_OPEN:
    case Enums.ACTIONS.RIGHT_MENU_CLOSE:
      return Object.assign({}, state, {
        ...state,
        rightMenu: {
          ...state.rightMenu,
          visible: !state.rightMenu.visible
        }
      })
    case Enums.ACTIONS.SIGN_IN:
      tmpMenuItems = state.leftMenu.menuItems.concat()

      return Object.assign({}, state, {
        ...state,
        tabNavigation: {
          ...state.tabNavigation,
          enabled: true
        },
        leftMenu: {
          ...state.leftMenu,
          leftMenuEnabled: true,
          menuItems: tmpMenuItems
        },
        rootContent: CourseListView,
        toolbar: {
          ...state.toolbar,
          customMenus: {
            content: <CustomMenu />
          }
        }
      })
    case Enums.ACTIONS.SIGN_OUT:
      return Object.assign({}, AgiliteReactState)
    case Enums.ACTIONS.NEW_COURSE:
      tmpValue = {
        key: Enums.KEYS.NEW_COURSE,
        closeable: true,
        title: Enums.TITLES.NEW_COURSE,
        content: (
          <CourseForm
            getAllCourses={action.payload.getAllCourses}
            courseData={action.payload.dataTemplate}
            isNew={true}
          />
        )
      }

      return handleTabAdd(Enums.KEYS.NEW_COURSE, tmpValue, state)
    case Enums.ACTIONS.EDIT_COURSE:
      tmpValue = {
        key: Enums.KEYS.EDIT_COURSE,
        closeable: true,
        title: 'Edit: ' + truncate(action.payload.courseData.title),
        content: <CourseForm courseData={action.payload.courseData} getAllCourses={action.payload.getAllCourses} />
      }

      return handleTabAdd(Enums.KEYS.EDIT_COURSE, tmpValue, state)
    case Enums.ACTIONS.OPEN_COURSE:
      tmpValue = {
        key: action.payload.courseData.id,
        closeable: true,
        title: truncate(action.payload.courseData.title),
        content: (
          <CourseForm
            courseData={action.payload.courseData}
            getAllCourses={action.payload.getAllCourses}
            isNew={false}
          />
        )
      }

      return handleTabAdd(action.payload.courseData.id, tmpValue, state)
    default:
      return state
  }
}

export default CoreReducer
