import { getAppStateTemplate } from 'utils/data-templates'
import Enums from 'utils/enums'

const appReducer = (state = getAppStateTemplate(), action) => {
  let tmpCourses = null
  let tmpIndex = null

  switch (action.type) {
    case Enums.ACTIONS.SET_COURSE:
      return Object.assign({}, state, {
        ...state,
        courses: action.payload.courses
      })
    case Enums.ACTIONS.SET_USER:
      return Object.assign({}, state, {
        ...state,
        user: action.payload
      })
    case Enums.ACTIONS.SET_INIT_CONFIG:
      return Object.assign({}, state, {
        ...state,
        ...action.payload
      })
    case Enums.ACTIONS.UPDATE_COURSE:
      tmpCourses = state.courses.concat()

      tmpIndex = tmpCourses.findIndex((entry) => entry._id === action.payload._id)

      if (tmpIndex !== -1) tmpCourses[tmpIndex] = action.payload

      return Object.assign({}, state, {
        ...state,
        courses: tmpCourses
      })
    case Enums.ACTIONS.DELETE_COURSE:
      tmpCourses = state.courses.concat()
      tmpCourses.splice(action.payload, 1)

      return Object.assign({}, state, {
        ...state,
        courses: tmpCourses
      })
    default:
      return state
  }
}

export default appReducer
