import { getFirestore, doc, setDoc, deleteDoc, getDocs, collection } from 'firebase/firestore'

export const setCategory = (category = '', uid = '') => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null
      let data = null

      try {
        if (!category) throw new Error('category parameter is null or empty')
        db = getFirestore()
        if (!uid) uid = category.replace(/[^\w]/gi, '').toLowerCase()
        data = { category }

        await setDoc(doc(db, 'categories', uid), data, { merge: true })
        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const deleteCategory = (uid = '') => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null

      try {
        if (!uid) throw new Error('uid parameter is null or empty')
        db = getFirestore()
        await deleteDoc(doc(db, 'categories', uid))
        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getAllCategories = () => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null
      let data = null
      let result = []
      let tmpEntry = null

      try {
        db = getFirestore()
        data = await getDocs(collection(db, 'categories'))

        data.forEach((doc) => {
          tmpEntry = doc.data()
          tmpEntry.id = doc.id
          result.push(tmpEntry)
        })

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
