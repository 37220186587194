import { v1 } from 'agilite-utils/uuid'

export const courseDataTemplate = () => {
  return {
    id: v1(),
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    title: '',
    description: '',
    overview: '',
    image: null,
    tags: [],
    categories: '',
    levels: [],
    lessons: [],
    questions: []
  }
}

export const levelDataTemplate = () => {
  return {
    id: '',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    label: ''
  }
}

export const lessonDataTemplate = () => {
  return {
    id: '',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    title: '',
    description: '',
    levelId: '',
    videoUrl: '',
    audioUrl: '',
    skillPoints: null,
    skillCoins: null,
    content: ''
  }
}

export const questionDataTemplate = () => {
  return {
    id: '',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    levelId: '',
    question: '',
    skillPoints: null,
    answers: []
  }
}

export const answerDataTemplate = () => {
  return { value: '', answerCorrect: false }
}

export const filterDataTemplate = () => {
  return {
    tags: [],
    categories: [],
    filterValue: 0
  }
}

export const labelValueObjectTemplate = () => {
  return { category: '', id: '' }
}

export const getAppStateTemplate = () => {
  return {
    initialized: false,
    courses: [],
    anonymousAccess: {
      enabled: false,
      isDefault: false
    },
    user: {
      uid: '',
      authComplete: false,
      data: {}
    }
  }
}

export const getUserTemplate = () => {
  return {
    firstName: '',
    lastName: '',
    username: '',
    profilePicId: '',
    profileComplete: false,
    state: {
      general: {
        initialized: false,
        skillPoints: 0,
        skillCoins: 0
      },
      courses: {}
    }
  }
}
