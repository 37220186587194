import { v1 } from 'agilite-utils/uuid'
import { getFirestore, doc, setDoc, deleteDoc, getDocs, collection, getDoc, query, orderBy } from 'firebase/firestore'

export const setCourseHeader = (data, custom) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null
      let tmpData = null
      let headerId = null
      let uid = null
      let content = null
      let timestamp = new Date().toISOString()
      let index = null
      let tmpLesson = null

      try {
        if (!data) throw new Error('data parameter is null or empty')
        db = getFirestore()
        if (!custom.userId) throw new Error('userId parameter is null or empty')

        // Course Header
        headerId = data.header.id
        delete data.header.id

        if (custom.isNew) {
          tmpData = {
            ...data.header,
            createdAt: timestamp,
            createdBy: custom.userId,
            modifiedAt: timestamp,
            modifiedBy: custom.userId
          }
        } else {
          tmpData = { ...data.header, modifiedAt: timestamp, modifiedBy: custom.userId }
        }

        await setDoc(doc(db, 'courses', headerId), tmpData, { merge: true })

        // Course Levels
        if (data.levels) {
          data.levels.map(async (level, index) => {
            uid = level.id
            delete level.id

            // Set Sort Order
            level.sortOrder = index + 1

            if (!level.createdBy) {
              level = {
                ...level,
                createdAt: timestamp,
                createdBy: custom.userId,
                modifiedAt: timestamp,
                modifiedBy: custom.userId
              }
            } else {
              level = {
                ...level,
                modifiedAt: timestamp,
                modifiedBy: custom.userId
              }
            }

            await setDoc(doc(db, `courses/${headerId}/levels`, uid), level, { merge: true })
          })
        }

        // Course Lessons
        if (data.lessons) {
          index = 0

          for (const lesson of data.lessons) {
            uid = lesson.id
            content = lesson.contentData
            lesson.content = lesson.content || v1()
            delete lesson.id

            // Set Sort Order
            lesson.sortOrder = index + 1

            if (!lesson.createdBy) {
              tmpLesson = {
                ...lesson,
                createdAt: timestamp,
                createdBy: custom.userId,
                modifiedAt: timestamp,
                modifiedBy: custom.userId
              }
            } else {
              tmpLesson = {
                ...lesson,
                modifiedAt: timestamp,
                modifiedBy: custom.userId
              }
            }

            delete tmpLesson.contentData
            console.log(tmpLesson.content, content)
            await setDoc(doc(db, `courses/${headerId}/lessons`, uid), tmpLesson, { merge: true })

            if (content) {
              await setDoc(doc(db, `courses/${headerId}/content/`, tmpLesson.content), { content }, { merge: true })
            }

            index++
          }
        }

        // Course Questions
        if (data.questions) {
          data.questions.map(async (question, index) => {
            uid = question.id
            delete question.id

            // Set Sort Order
            question.sortOrder = index + 1

            if (!question.createdBy) {
              question = {
                ...question,
                createdAt: timestamp,
                createdBy: custom.userId,
                modifiedAt: timestamp,
                modifiedBy: custom.userId
              }
            } else {
              question = {
                ...question,
                modifiedAt: timestamp,
                modifiedBy: custom.userId
              }
            }

            await setDoc(doc(db, `courses/${headerId}/questions`, uid), question, { merge: true })
          })
        }

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const deleteCourseHeader = (course, uid) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null

      try {
        if (!uid) throw new Error('uid parameter is null or empty')
        db = getFirestore()
        await deleteDoc(doc(db, 'courseHeaders', uid))

        // Delete Course Lessons
        course.lessons.map(async (lesson) => {
          await deleteDoc(doc(db, 'courseLessons', lesson.id))
        })

        // Delete Course Questions
        course.questions.map(async (question) => {
          await deleteDoc(doc(db, 'courseQuestions', question.questionId))
        })

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getCourseHeaders = () => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null
      let data = null
      let result = []
      let tmpEntry = null

      try {
        db = getFirestore()
        data = await getDocs(collection(db, 'courses'))

        data.forEach((doc) => {
          tmpEntry = doc.data()
          tmpEntry.id = doc.id
          result.push(tmpEntry)
        })

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getCourseLevels = (courseId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null
      let data = null
      let result = []
      let tmpEntry = null

      try {
        db = getFirestore()
        data = await getDocs(query(collection(db, `courses/${courseId}/levels`), orderBy('sortOrder')))

        data.forEach((doc) => {
          tmpEntry = doc.data()
          tmpEntry.id = doc.id
          result.push(tmpEntry)
        })

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getCourseLessons = (courseId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null
      let data = null
      let result = []
      let tmpEntry = null

      try {
        db = getFirestore()
        data = await getDocs(query(collection(db, `courses/${courseId}/lessons`), orderBy('sortOrder')))

        data.forEach((doc) => {
          tmpEntry = doc.data()
          tmpEntry.id = doc.id
          result.push(tmpEntry)
        })

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getLessonContent = (courseId, lessonId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null
      let result = null

      try {
        db = getFirestore()
        result = await getDoc(doc(db, `courses/${courseId}/content`, lessonId))

        resolve(result.data())
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getCourseQuestions = (courseId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let db = null
      let data = null
      let result = []
      let tmpEntry = null

      try {
        db = getFirestore()
        data = await getDocs(query(collection(db, `courses/${courseId}/questions`), orderBy('sortOrder')))

        data.forEach((doc) => {
          tmpEntry = doc.data()
          tmpEntry.id = doc.id
          result.push(tmpEntry)
        })

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
