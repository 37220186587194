import React, { memo, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Button, Table, message, Input, Form, Select, Radio, Pagination, Alert, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { SyncOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import Debounce from 'lodash/debounce'

import { searchCourses } from '../../utils/utilities'
import Theme from '../../styles/theme'
import { courseDataTemplate, filterDataTemplate } from '../../utils/data-templates'
import { handleCreateNewCourse, handleDeleteCourse, handleOpenCourse, handleSetCourses } from '../course-actions'
import { getAllCategories } from 'categories/controller'
import { deleteCourseHeader, getCourseHeaders } from 'courses/controller'

// Globals
let debouncedFn = null

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
}

const CourseListView = () => {
  const dispatch = useDispatch()
  const reduxCourses = useSelector((state) => state.app.courses)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    isLoading: false,
    categories: [],
    page: 1,
    totalRecords: 0,
    searchValue: '',
    showFilterOptions: false,
    filterOptions: filterDataTemplate(),
    filterAlertVisible: false
  })

  useEffect(() => {
    getAllCourses()
    // eslint-disable-next-line
  }, [state.page])

  const getAllCourses = async () => {
    let categories = []
    let courses = []

    try {
      setState({ isLoading: true })

      categories = await getAllCategories()
      courses = await getCourseHeaders()

      dispatch(handleSetCourses(courses))
      setState({ categories, totalRecords: courses.length, isLoading: false })
    } catch (e) {
      message.error(e.message)
    }
  }

  const newCourse = () => {
    const dataTemplate = courseDataTemplate()
    dispatch(handleCreateNewCourse(dataTemplate, getAllCourses))
  }

  const openCourse = (courseData) => {
    dispatch(handleOpenCourse(courseData, getAllCourses))
  }

  const deleteCourse = async (index, course, id) => {
    try {
      await deleteCourseHeader(course, id)
    } catch (e) {
      message.error(e.message)
    }
    dispatch(handleDeleteCourse(index))
  }

  const handleSearch = (e) => {
    setState({ searchValue: e.target.value })
  }

  const applyFilter = () => {
    let newCourses = state.courses
    let tmpCourses = []

    if (state.searchValue) {
      if (state.filterOptions.author) {
        newCourses = newCourses.filter((entry) => entry.createdCN === state.filterOptions.author)
      }

      if (state.filterOptions.tags.length > 0) {
        newCourses.map((tmpCourse) => {
          const found = state.filterOptions.tags.some((tag) => tmpCourse.tags.includes(tag))

          if (found) tmpCourses.push(tmpCourse)

          return null
        })

        newCourses = tmpCourses
        tmpCourses = []
      }

      if (state.filterOptions.categories.length > 0) {
        newCourses.map((tmpCourse) => {
          const courseCategoryValues = tmpCourse.categories.map((tmpCategory) => tmpCategory.value)
          const filterCategoryValues = state.filterOptions.categories.map((tmpCategory) => tmpCategory.value)

          const found = filterCategoryValues.some((category) => courseCategoryValues.includes(category))

          if (found) tmpCourses.push(tmpCourse)

          return null
        })

        newCourses = tmpCourses
        tmpCourses = []
      }

      switch (state.filterOptions.filterValue) {
        case 1: // My Courses
          newCourses.map((tmpCourse) => {
            if (tmpCourse.createdBy === '') tmpCourses.push(tmpCourse) // TODO: Reference username from Firebase
            return null
          })
          newCourses = tmpCourses
          tmpCourses = []
          break
        default:
          break
      }

      setState({ courses: newCourses, showFilterOptions: false, filterAlertVisible: true })
    }
  }

  return (
    <div>
      <Row justify='center'>
        <Col span={20}>
          <Card
            type='inner'
            title={
              <Row>
                <Col span={24}>
                  <Input
                    prefix={<SearchOutlined />}
                    value={state.searchValue}
                    onChange={(event) => {
                      handleSearch(event)

                      /* signal to React not to nullify the event object */
                      event.persist()

                      if (!debouncedFn) {
                        debouncedFn = Debounce(() => {
                          setState({ isLoading: true })
                          if (!event.target.value) {
                            getAllCourses()
                            setState({ isLoading: false })
                          } else {
                            searchCourses(state.filterOptions, state.page, event.target.value, (errMsg, res) => {
                              setState({ isLoading: false })

                              if (errMsg) {
                                message.error(errMsg)
                              } else {
                                setState({ courses: res })
                              }
                            })
                          }
                        }, 1000)
                      }

                      debouncedFn()
                    }}
                    style={{ width: '99%' }}
                    placeholder='Search Courses...'
                  />
                </Col>
              </Row>
            }
            extra={
              <Row justify='space-between'>
                <Col>
                  <Button type='primary' style={{ borderRadius: 5 }} onClick={newCourse}>
                    Create Course
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => setState({ showFilterOptions: !state.showFilterOptions })}
                    style={{ borderRadius: 5, marginLeft: 10 }}
                  >
                    <SettingOutlined /> Filter
                  </Button>
                </Col>
                <Col>
                  <Button
                    type='default'
                    style={{
                      borderRadius: 5,
                      fontSize: 16,
                      marginLeft: 10,
                      backgroundColor: Theme.twitterBootstrap.success,
                      color: Theme.white
                    }}
                    onClick={() => {
                      setState({
                        page: 1,
                        filterOptions: filterDataTemplate(),
                        searchValue: '',
                        filterAlertVisible: false
                      })
                      getAllCourses(filterDataTemplate())
                    }}
                  >
                    <SyncOutlined spin={state.isLoading} />
                  </Button>
                </Col>
              </Row>
            }
          >
            {state.showFilterOptions ? (
              <Card
                type='inner'
                title='Filter Options'
                size='small'
                style={{ marginBottom: 10, backgroundColor: '#eceff1' }}
              >
                {/* TODO: Setup correct filters */}
                <Form>
                  <Row justify='space-around' gutter={20}>
                    <Col span={11}>
                      <h3>Filter By</h3>
                      <Radio.Group
                        value={state.filterOptions.filterValue}
                        onChange={(e) => {
                          setState({
                            filterOptions: (prevState) => ({
                              ...prevState,
                              filterValue: e.target.value
                            })
                          })
                        }}
                      >
                        <Radio style={radioStyle} value={0}>
                          None
                        </Radio>
                        <Radio style={radioStyle} value={1}>
                          My Courses
                        </Radio>
                      </Radio.Group>
                    </Col>
                    <Col span={11}>
                      <h3>Tagged With</h3>
                      <Form.Item>
                        The following tags
                        <Select
                          value={state.filterOptions.tags}
                          onChange={(tags) => setState({ filterOptions: (prevState) => ({ ...prevState, tags }) })}
                          mode='tags'
                        />
                      </Form.Item>
                      <h3>Categorized With</h3>
                      <Form.Item>
                        The following categories
                        <Select
                          mode='multiple'
                          value={state.filterOptions.categories}
                          labelInValue
                          onChange={(categories, options) => {
                            const tmpCategories = options.map((category) => {
                              return {
                                label: category.children,
                                value: category.value
                              }
                            })

                            setState({ filterOptions: (prevState) => ({ ...prevState, categories: tmpCategories }) })
                          }}
                        >
                          {state.categories.map((item) => {
                            return (
                              <Select.Option key={item.label} value={item.value}>
                                {item.label}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Button onClick={applyFilter} type='primary' style={{ margin: 5 }}>
                      Apply
                    </Button>
                    <Button type='default' style={{ margin: 5 }}>
                      Reset
                    </Button>
                  </Row>
                </Form>
              </Card>
            ) : null}
            {state.filterAlertVisible ? <Alert showIcon type='info' message='Filters have been applied' /> : null}
            <Table
              columns={[
                {
                  title: 'Title',
                  dataIndex: 'title',
                  key: 'title',
                  render: (value, record) => {
                    // eslint-disable-next-line
                    return <a onClick={() => openCourse(record)}>{value}</a>
                  }
                },
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description'
                },
                {
                  title: 'Actions',
                  key: 'actions',
                  width: '10%',
                  render: (value, record, index) => {
                    // eslint-disable-next-line
                    return (
                      <Popconfirm
                        title='Are you sure you want to Delete this Course?'
                        onConfirm={() => deleteCourse(index, record, record.id)}
                        okText='Yes'
                      >
                        <DeleteOutlined style={{ color: Theme.twitterBootstrap.danger }} />
                      </Popconfirm>
                    )
                  }
                }
              ]}
              dataSource={reduxCourses}
              bordered
              size='small'
              loading={state.isLoading}
              pagination={false}
              className='time-table-row-select'
              rowKey={(item) => item.id}
            />
            <center style={{ margin: 10 }}>
              <Pagination
                showSizeChanger
                current={state.page}
                total={state.totalRecords}
                onChange={(value) => setState({ page: value })}
              />
            </center>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const _CourseListView = memo(CourseListView)
export default _CourseListView
