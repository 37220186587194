import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Col, Form, Input, message, Modal, Popconfirm, Row, Select, Switch, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import Theme from 'styles/theme'
import { answerDataTemplate, questionDataTemplate } from 'utils/data-templates'
import { v1 } from 'agilite-utils/uuid'
import { getCourseQuestions } from 'courses/controller'

const Questions = ({ levels, handleChange, course }) => {
  // Question State
  const [formOpen, setFormOpen] = useState(false)
  const [questions, setQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState({ ...questionDataTemplate() })
  const [questionIndex, setQuestionIndex] = useState(undefined)
  const [loading, setLoading] = useState(false)

  // Answer State
  const [currentAnswer, setCurrentAnswer] = useState({ ...answerDataTemplate() })
  const [answerIndex, setAnswerIndex] = useState(undefined)
  const [answerFormOpen, setAnswerFormOpen] = useState(false)

  useEffect(() => {
    handleGetCourseQuestions()
    // eslint-disable-next-line
  }, [])

  const handleGetCourseQuestions = async () => {
    let tmpQuestions = []

    try {
      setLoading(true)
      tmpQuestions = await getCourseQuestions(course.id)
      setQuestions(tmpQuestions)
      setLoading(false)
    } catch (e) {
      message.error(e.message)
      setLoading(false)
    }
  }

  const handleQuestionOpen = (questionData, index) => {
    setCurrentQuestion(questionData)
    setQuestionIndex(index)
    setFormOpen(true)
  }

  const handleCreateQuestion = () => {
    setCurrentQuestion({ ...questionDataTemplate(), id: Date.now().toString() })
    setQuestionIndex(-1)
    setFormOpen(true)
  }

  const closeForm = () => {
    setFormOpen(false)
  }

  const handleAnswerOpen = (answerData, index) => {
    setCurrentAnswer(answerData)
    setAnswerIndex(index)
    setAnswerFormOpen(true)
  }

  const handleCreateAnswer = () => {
    setCurrentAnswer({ ...answerDataTemplate() })
    setAnswerIndex(-1)
    setAnswerFormOpen(true)
  }

  const closeAnswerForm = () => {
    setAnswerFormOpen(false)
  }

  const submitQuestion = () => {
    const tmpQuestions = JSON.parse(JSON.stringify(questions))
    let errorMessage = null

    // Validate
    if (!currentQuestion.levelId) {
      errorMessage = 'Please select a Level'
    } else if (!currentQuestion.question) {
      errorMessage = 'Please provide a Question'
    } else if (!currentQuestion.skillPoints) {
      errorMessage = 'Please provide Skill Points'
    } else if (currentQuestion.answers < 2) {
      errorMessage = 'Please provide at least 2 answers'
    }

    if (errorMessage) return message.error(errorMessage)

    if (questionIndex === -1) {
      tmpQuestions.push({ ...currentQuestion })
    } else {
      tmpQuestions.splice(questionIndex, 1, { ...currentQuestion })
    }

    closeForm()
    handleChange('questions', tmpQuestions)
    setQuestions(tmpQuestions)
    setCurrentQuestion({ ...questionDataTemplate() })
  }

  const submitAnswer = () => {
    const tmpCurrentQuestion = JSON.parse(JSON.stringify(currentQuestion))

    // Validate
    if (!currentAnswer.value) {
      return message.error('Please provide an Answer')
    }

    if (answerIndex === -1) {
      tmpCurrentQuestion.answers.push(currentAnswer)
    } else {
      tmpCurrentQuestion.answers.splice(answerIndex, 1, currentAnswer)
    }

    setCurrentQuestion(tmpCurrentQuestion)
    closeAnswerForm()
    setCurrentAnswer({ ...answerDataTemplate() })
  }

  const handleQuestionChange = (value, key) => {
    const tmpQuestion = JSON.parse(JSON.stringify(currentQuestion))
    tmpQuestion[key] = value
    setCurrentQuestion(tmpQuestion)
  }

  const handleAnswerChange = (value, key) => {
    const tmpAnswer = JSON.parse(JSON.stringify(currentAnswer))
    tmpAnswer[key] = value

    if (key === 'answerCorrect' && value === true) {
      const tmpCurrentQuestion = JSON.parse(JSON.stringify(currentQuestion))

      tmpCurrentQuestion.answers.map((answer) => {
        if (answer.answerCorrect) answer.answerCorrect = false
        return null
      })

      setCurrentQuestion(tmpCurrentQuestion)
    }

    setCurrentAnswer(tmpAnswer)
  }

  const deleteQuestion = (index) => {
    const tmpQuestions = JSON.parse(JSON.stringify(questions))
    tmpQuestions.splice(index, 1)
    handleChange('questions', tmpQuestions)
  }

  const deleteAnswer = (index) => {
    const tmpQuestion = JSON.parse(JSON.stringify(currentQuestion))
    tmpQuestion.answers.splice(index, 1)
    setCurrentQuestion(tmpQuestion)
  }

  return (
    <Card
      size='small'
      extra={
        <Button onClick={handleCreateQuestion} type='primary'>
          Add Question
        </Button>
      }
    >
      <Table
        loading={loading}
        dataSource={questions}
        columns={[
          {
            title: 'Question',
            key: 'question',
            dataIndex: 'question',
            width: '90%',
            render: (value, record, index) => {
              // eslint-disable-next-line
              return <a onClick={() => handleQuestionOpen(record, index)}>{value}</a>
            }
          },
          {
            title: 'Actions',
            key: 'actions',
            width: '10%',
            render: (value, record, index) => {
              // eslint-disable-next-line
              return (
                <Popconfirm
                  title='Are you sure you want to Delete this Question?'
                  onConfirm={() => deleteQuestion(index)}
                  okText='Yes'
                >
                  <DeleteOutlined style={{ color: Theme.twitterBootstrap.danger }} />
                </Popconfirm>
              )
            }
          }
        ]}
        size='small'
        bordered
        pagination={false}
        rowKey={() => v1()}
      />
      <Modal
        title='Question'
        onCancel={closeForm}
        onOk={submitQuestion}
        okText='Submit'
        closable={false}
        visible={formOpen}
        okButtonProps={{ title: 'Submit' }}
        destroyOnClose
        width={'50%'}
      >
        <Form>
          <Row>
            <Col span={12}>
              <Form.Item>
                <span>
                  Randomize order of Answers during Quiz?
                  <br />
                </span>
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  checked={currentQuestion.randomizeAnswers}
                  onChange={(value) => handleQuestionChange(value, 'randomizeAnswers')}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: Theme.custom.red }}>* </span>
                Level
                <Select
                  defaultValue={currentQuestion.levelId}
                  onChange={(value) => handleQuestionChange(value, 'levelId')}
                >
                  <Select.Option value=''>- Select a Level -</Select.Option>
                  {levels.map((level, index) => {
                    return (
                      <Select.Option key={index} value={level.id}>
                        Level {index + 1} - {level.label}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <span style={{ color: Theme.custom.red }}>* </span>
                Question
                <Input.TextArea
                  value={currentQuestion.question}
                  onChange={(e) => handleQuestionChange(e.target.value, 'question')}
                  rows={3}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: Theme.custom.red }}>* </span>
                Skill Points
                <Input
                  value={currentQuestion.skillPoints}
                  onChange={(e) => handleQuestionChange(e.target.value, 'skillPoints')}
                  type='number'
                />
              </Form.Item>
            </Col>
          </Row>
          <Card
            size='small'
            extra={
              <Button onClick={handleCreateAnswer} type='primary'>
                Add Answer
              </Button>
            }
          >
            <Table
              dataSource={currentQuestion ? currentQuestion.answers : []}
              columns={[
                {
                  title: 'Answer',
                  dataIndex: 'value',
                  key: 'value',
                  width: '90%',
                  render: (value, record, index) => {
                    // eslint-disable-next-line
                    return <a onClick={() => handleAnswerOpen(record, index)}>{value}</a>
                  }
                },
                {
                  title: 'Actions',
                  key: 'actions',
                  width: '10%',
                  render: (value, record, index) => {
                    // eslint-disable-next-line
                    return (
                      <Popconfirm
                        title='Are you sure you want to Delete this Answer?'
                        onConfirm={() => deleteAnswer(index)}
                        okText='Yes'
                      >
                        <DeleteOutlined style={{ color: Theme.twitterBootstrap.danger }} />
                      </Popconfirm>
                    )
                  }
                }
              ]}
              pagination={false}
              size='small'
              rowKey={() => v1()}
            />
          </Card>
          <Modal
            title='Answer'
            onCancel={closeAnswerForm}
            onOk={submitAnswer}
            okText='Submit'
            closable={false}
            visible={answerFormOpen}
            okButtonProps={{ title: 'Submit' }}
            destroyOnClose
            width={'40%'}
          >
            <Form>
              <Form.Item>
                <Checkbox
                  checked={currentAnswer.answerCorrect}
                  onChange={(e) => handleAnswerChange(e.target.checked, 'answerCorrect')}
                />
                <span> Correct Answer</span>
              </Form.Item>
              <Form.Item>
                <span style={{ color: Theme.custom.red }}>* </span>
                Answer
                <Input value={currentAnswer.value} onChange={(e) => handleAnswerChange(e.target.value, 'value')} />
              </Form.Item>
            </Form>
          </Modal>
        </Form>
      </Modal>
    </Card>
  )
}

export default Questions
