import React, { useEffect, useState } from 'react'
import ReactDragListView from 'react-drag-listview'
import { Card, Table, Button, Modal, Form, Input, message, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { levelDataTemplate } from 'utils/data-templates'
import Enums from 'utils/enums'
import Theme from 'styles/theme'
import { v1 } from 'agilite-utils/uuid'

const Levels = ({ handleChange, levels }) => {
  const [form] = Form.useForm()
  const [formOpen, setFormOpen] = useState(false)
  const [level, setLevel] = useState(null)
  const [tmpLevels, setTmpLevels] = useState(levels)
  const [levelIndex, setLevelIndex] = useState(null)

  const openForm = () => {
    setFormOpen(true)
  }

  const closeForm = () => {
    setFormOpen(false)
  }

  const handleCreateLevel = () => {
    form.resetFields()
    setLevel(levelDataTemplate())
    setLevelIndex(-1)
    openForm()
  }

  const handleLevelOpen = (levelObject, index) => {
    form.setFieldsValue(levelObject)
    setLevel(levelObject)
    setLevelIndex(index)
    openForm()
  }

  const submitLevel = () => {
    const tmpData = JSON.parse(JSON.stringify(levels))
    const entry = { ...form.getFieldsValue() }

    // Validate Level Data
    if (!entry.label) return message.error(Enums.MESSAGES.REQUIRED_LEVEL_NAME)

    if (levelIndex === -1) {
      entry.id = Date.now().toString()
      tmpData.push(entry)
    } else {
      entry.id = level.id
      tmpData.splice(levelIndex, 1, entry)
    }

    setTmpLevels(tmpData)
    handleChange('levels', tmpData)
    setLevel(levelDataTemplate())
    closeForm()
  }

  const deleteLevel = (index) => {
    const tmpData = JSON.parse(JSON.stringify(levels))
    tmpData.splice(index, 1)
    setTmpLevels(tmpData)
    handleChange('levels', tmpData)
    closeForm()
  }

  useEffect(() => {
    form.setFieldsValue(level)
  }, [level, form])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = JSON.parse(JSON.stringify(levels))
      const item = data.splice(fromIndex, 1)[0]
      data.splice(toIndex, 0, item)
      handleChange('levels', data)
      setTmpLevels(data)
    },
    handleSelector: '.drag-handle-levels'
  }

  return (
    <Card
      size='small'
      extra={
        <Button type='primary' onClick={handleCreateLevel}>
          Create Level
        </Button>
      }
    >
      <ReactDragListView {...dragProps}>
        <Table
          rowClassName='drag-handle-levels'
          dataSource={tmpLevels}
          columns={[
            {
              title: 'Level Name',
              key: 'label',
              dataIndex: 'label',
              render: (value, record, index) => {
                return (
                  // eslint-disable-next-line
                  <a onClick={() => handleLevelOpen(record, index)}>
                    Level {index + 1} - {value}
                  </a>
                )
              }
            },
            {
              title: 'Actions',
              key: 'actions',
              width: '10%',
              render: (value, record, index) => {
                // eslint-disable-next-line
                return (
                  <Popconfirm
                    title='Are you sure you want to Delete this Level?'
                    onConfirm={() => deleteLevel(index)}
                    okText='Yes'
                  >
                    <DeleteOutlined style={{ color: Theme.twitterBootstrap.danger }} />
                  </Popconfirm>
                )
              }
            }
          ]}
          size='small'
          bordered
          pagination={false}
          rowKey={() => v1()}
        />
      </ReactDragListView>
      <Modal
        title={level ? 'Edit Level' : 'New Level'}
        onCancel={closeForm}
        onOk={submitLevel}
        closable={false}
        visible={formOpen}
        okText='Submit'
        destroyOnClose
        width={'40%'}
        getContainer={false}
      >
        <Form
          form={form}
          name='basic'
          initialValues={levelDataTemplate()}
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          labelAlign='left'
        >
          <Form.Item required label='Level Name' name='label'>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  )
}

export default Levels
