import Enums from '../utils/enums'

const handleMenuItemClick = (e) => {
  return {
    type: Enums.ACTIONS.ITEM_CLICK,
    key: e.key
  }
}

const handleLeftMenuClose = (e) => {
  return {
    type: Enums.ACTIONS.LEFT_MENU_CLOSE
  }
}

const handleLeftMenuOpen = (e) => {
  return {
    type: Enums.ACTIONS.LEFT_MENU_OPEN
  }
}

const handleRightMenuClose = (e) => {
  return {
    type: Enums.ACTIONS.RIGHT_MENU_CLOSE
  }
}

const handleRightMenuOpen = (e) => {
  return {
    type: Enums.ACTIONS.RIGHT_MENU_OPEN
  }
}

const handleTabChange = (key) => {
  return {
    type: Enums.ACTIONS.TAB_CHANGE,
    key
  }
}

const handleTabClose = (key) => {
  return {
    type: Enums.ACTIONS.TAB_CLOSE,
    key
  }
}

const handleSignIn = () => {
  return {
    type: Enums.ACTIONS.SIGN_IN
  }
}

const handleSignOut = () => {
  return {
    type: Enums.ACTIONS.SIGN_OUT
  }
}

export {
  handleMenuItemClick,
  handleLeftMenuClose,
  handleRightMenuClose,
  handleLeftMenuOpen,
  handleRightMenuOpen,
  handleTabChange,
  handleTabClose,
  handleSignIn,
  handleSignOut
}
