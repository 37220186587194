import React, { useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Row, Card, Button, message, Popconfirm, Tabs } from 'antd'
import AgiliteUtils from 'agilite-utils'

import { formatAliasValue, formatCourseData } from '../../utils/utilities'
import { handleTabClose } from '../../core/core-actions'

import Theme from '../../styles/theme'
import Enums from '../../utils/enums'

import General from './course-form/general'
import Overview from './course-form/overview'
import Levels from './course-form/levels'
import Lessons from './course-form/lessons'
import Questions from './course-form/questions'
import { getCourseLevels, setCourseHeader } from 'courses/controller'
import { getAllCategories } from 'categories/controller'

const CourseNewForm = ({ courseData, getAllCourses, isNew }) => {
  const dispatch = useDispatch()
  const reduxTabNavigation = useSelector((state) => state.core.tabNavigation)
  const reduxUserId = useSelector((state) => state.app.user.uid)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    categories: [],
    categoriesLoading: false,
    selectedTab: 'write',
    course: {},
    levels: [],
    image: null
  })

  useEffect(() => {
    if (courseData) {
      const course = JSON.parse(JSON.stringify(courseData))
      const image = course.image
      setState({ course, image })
    }
    // eslint-disable-next-line
  }, [])

  // Only run this when we have a Course Record
  useEffect(() => {
    if (!state.course.id) return
    handleGetCategories()
    handleGetCourseLevels()

    // eslint-disable-next-line
  }, [state.course.id])

  const handleGetCategories = async () => {
    try {
      setState({ categoriesLoading: true })
      const categories = await getAllCategories()
      setState({ categories, categoriesLoading: true })
    } catch (e) {
      message.error(e.message)
    }
  }

  const closeCurrentTab = () => {
    dispatch(handleTabClose(reduxTabNavigation.activeKey))
  }

  const submitCourse = async (course) => {
    let tmpData = null

    try {
      await AgiliteUtils.trimObjectValues(course)

      if (!course.title) {
        return message.error(Enums.MESSAGES.REQUIRED_COURSE_TITLE)
      } else if (!course.description) {
        return message.error(Enums.MESSAGES.REQUIRED_COURSE_DESCRIPTION)
      } else if (course.tags.length > Enums.COURSE_VALUES.TAG_LIMIT) {
        return message.error(`${Enums.MESSAGES.SPECIFY_PREFIX} ${Enums.COURSE_VALUES.TAG_LIMIT} Tags`)
      } else if ([course.categories].length > Enums.COURSE_VALUES.CATEGORY_LIMIT) {
        return message.error(`${Enums.MESSAGES.SPECIFY_PREFIX} ${Enums.COURSE_VALUES.CATEGORY_LIMIT} Categories`)
      }

      tmpData = await formatCourseData(course)
      tmpData.header.image = state.image
      await setCourseHeader(tmpData, { userId: reduxUserId, isNew })

      message.success('Course created successfully')
      getAllCourses()
      closeCurrentTab()
    } catch (e) {
      message.error(e.message)
      console.error(e)
    }
  }

  const handleChange = (key, value) => {
    const course = state.course
    if (!course.id) return

    course[key] = value
    setState({ course })
    if (key === 'levels') setState({ levels: value })
  }

  const handleChangeImage = (image) => {
    setState({ image })
  }

  const handleTagChange = (values) => {
    const tmpTags = []
    const tags = JSON.parse(JSON.stringify(values))
    let tmpTag = null

    tags.map((tag) => {
      if (tag.trim() !== '') {
        tmpTag = formatAliasValue(tag, 'tag')
        tmpTags.push(tmpTag)
      }

      return null
    })

    handleChange('tags', tmpTags)
  }

  const handleGetCourseLevels = async () => {
    try {
      const levels = await getCourseLevels(state.course.id)
      setState({ levels })
      handleChange('levels', levels)
    } catch (e) {
      message.error(e.message)
    }
  }

  const handleSetSelectedTab = async (selectedTab) => {
    try {
      setState({ selectedTab })
    } catch (e) {
      message.error(e.message)
    }
  }

  return (
    <Row justify='center'>
      <Col xs={24} sm={22} md={18} lg={16} xl={14} xxl={12}>
        <Card type='inner' title='New Course'>
          <Tabs type='card' animated={false} defaultActiveKey='general'>
            <Tabs.TabPane key='general' tab='General'>
              <General
                course={state.course}
                courseImage={state.image}
                onChange={handleChange}
                onChangeImage={handleChangeImage}
                categoryData={{
                  categoriesLoading: state.categoriesLoading,
                  handleGetCategories,
                  categories: state.categories
                }}
                tagData={{
                  handleTagChange
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key='overview' tab='Course Overview'>
              <Overview
                course={state.course}
                onChange={handleChange}
                selectedTab={state.selectedTab}
                onSetSelectedTab={handleSetSelectedTab}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key='levels' tab='Levels'>
              <Levels handleChange={handleChange} levels={state.levels} />
            </Tabs.TabPane>
            <Tabs.TabPane key='lessons' tab='Lessons'>
              <Lessons handleChange={handleChange} course={state.course} levels={state.levels} />
            </Tabs.TabPane>
            <Tabs.TabPane key='questions' tab='Quiz Questions'>
              <Questions handleChange={handleChange} course={state.course} levels={state.levels} />
            </Tabs.TabPane>
          </Tabs>
          <div style={{ marginTop: 20 }}>
            <Popconfirm
              title='Are you sure you want to Submit this Course?'
              onConfirm={() => submitCourse(state.course)}
              okText='Yes'
            >
              <Button type='primary'>Submit</Button>
            </Popconfirm>
            <Popconfirm title='Are you sure you want to Cancel?' onConfirm={closeCurrentTab} okText='Yes'>
              <Button
                type='default'
                style={{ backgroundColor: Theme.twitterBootstrap.danger, color: Theme.white, marginLeft: 10 }}
              >
                Cancel
              </Button>
            </Popconfirm>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default CourseNewForm
