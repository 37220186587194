import React, { useEffect, useReducer } from 'react'
import { message } from 'antd'
import {
  sendSignInLinkToEmail,
  signInWithRedirect,
  getRedirectResult,
  FacebookAuthProvider,
  GoogleAuthProvider
} from 'firebase/auth'

import LoginEmailLink from './LoginEmailLink'
import LoginEmailSuccess from './LoginEmailSuccess'
import LoginOptions from './LoginOptions'

import Enums from '-new/lib/enums'
import { auth } from '-new/lib/firebase'

const LoginUser = () => {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    facet: Enums.loginFacets.OPTIONS,
    disableForm: false,
    authError: true,
    emailSignInComplete: false,
    loginType: 'login'
  })

  // Runs on load of component
  useEffect(() => {
    async function init() {
      let errMsg = null

      try {
        // Check if an auth provider redirect occurred
        await getRedirectResult(auth)
        setState({ authError: false })
      } catch (e) {
        switch (e.code) {
          case 'auth/account-exists-with-different-credential':
            // User logged in using an incorrect provider
            errMsg =
              "You created your Metability account using a method different to the one you used now. Please try a different method, or alternatively, select the 'Quick Email' option."
            message.error({
              content: errMsg,
              duration: 10,
              className: 'message-prompt'
            })
            break
          default:
            console.error(e.message)
        }
      }
    }

    init()
    // eslint-disable-next-line
  }, [])

  const handleSelectOption = async (facet) => {
    let provider = null

    try {
      // First Set Provider
      switch (facet) {
        case Enums.loginFacets.FACEBOOK:
          provider = new FacebookAuthProvider()
          break
        case Enums.loginFacets.GOOGLE:
          provider = new GoogleAuthProvider()
          break
      }

      // Then Perform Auth
      switch (facet) {
        case Enums.loginFacets.FACEBOOK:
        case Enums.loginFacets.GOOGLE:
          await signInWithRedirect(auth, provider)
          break
        default:
          setState({ facet })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleCancelOption = async () => {
    setState({ facet: Enums.loginFacets.OPTIONS })
  }

  const handleLoginEmailLink = async (email) => {
    const facet = Enums.loginFacets.EMAIL_LINK_SUCCESS
    let actionCodeSettings = null

    try {
      setState({ disableForm: true })

      actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: true
      }

      await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      window.localStorage.setItem('emailForSignIn', email)

      setState({ facet, disableForm: false })
    } catch (e) {
      setState({ disableForm: false })
      alert(e.message)
    }
  }

  return (
    <>
      {!state.authError ? (
        <>
          {state.facet === Enums.loginFacets.OPTIONS ? (
            <LoginOptions loginType={state.loginType} onSelectOption={handleSelectOption} />
          ) : undefined}
          {state.facet === Enums.loginFacets.EMAIL_LINK ? (
            <LoginEmailLink
              loginType={state.loginType}
              onSubmit={handleLoginEmailLink}
              onCancelOption={handleCancelOption}
              disableForm={state.disableForm}
            />
          ) : undefined}
          {state.facet === Enums.loginFacets.EMAIL_LINK_SUCCESS ? (
            <LoginEmailSuccess onCancelOption={handleCancelOption} />
          ) : undefined}
        </>
      ) : undefined}
    </>
  )
}

export default LoginUser
