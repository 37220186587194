export const createCourse = (data, callback) => {
  // TODO: callback(error, create result)
  callback(null, {})
}

export const updateCourse = (data, callback) => {
  // TODO: callback(error, update result)
  callback(null, {})
}

export const getCategories = (callback) => {
  // TODO: callback(error, categories)
  callback(null, {})
}

export const updateCategories = (data, updateType, category, callback) => {
  // TODO: callback(error, categories)
  callback(null, {})
}

export const searchCourses = (filterOptions, page, searchValue, callback) => {
  // TODO: callback(error, courses, courses length)
  callback(null, {}, 0)
}

export const handleTabAdd = (activeKey, tab, state) => {
  const tmpState = Object.assign({}, state)
  const tmpTabs = tmpState.tabNavigation.tabs.concat()
  let tabExists = false

  tmpState.tabNavigation.tabs.map((tab) => {
    if (tab.key === activeKey) tabExists = true
    return null
  })

  if (tabExists) {
    return Object.assign({}, tmpState, {
      ...tmpState,
      tabNavigation: {
        ...tmpState.tabNavigation,
        activeKey
      }
    })
  } else {
    tmpTabs.push(tab)

    return Object.assign({}, tmpState, {
      ...tmpState,
      tabNavigation: {
        ...tmpState.tabNavigation,
        activeKey,
        tabs: tmpTabs
      }
    })
  }
}

export const formatAliasValue = (value, type) => {
  if (type && type === 'tag') {
    return value
      .replace(/[^A-Z0-9_ ]/gi, '')
      .trim()
      .toLowerCase()
  } else {
    return value
      .replace(/[^A-Z0-9]/gi, '')
      .trim()
      .toLowerCase()
  }
}

export const formatCourseData = (course) => {
  return new Promise((resolve, reject) => {
    const result = {
      header: null,
      levels: [],
      lessons: [],
      questions: []
    }

    let tmpCourse = null
    let errMsg = null

    try {
      // Validate
      if (!course) {
        errMsg = 'Course object passed is null or undefined'
        throw new Error(errMsg)
      }

      tmpCourse = JSON.parse(JSON.stringify(course))

      // First populate Levels, Lessons and Questions
      result.levels = tmpCourse.levels
      result.lessons = tmpCourse.lessons
      result.questions = tmpCourse.questions

      // Populate header and remove unneeded props
      result.header = tmpCourse

      delete result.header.levels
      delete result.header.lessons
      delete result.header.questions

      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}
