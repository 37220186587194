import React from 'react'
import { Card, Form } from 'antd'
import Theme from '../../../styles/theme'

import ReactMde from 'react-mde'
import * as Showdown from 'showdown'
import 'react-mde/lib/styles/css/react-mde-all.css'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  parseImgDimensions: true
})

const Overview = ({ onChange, selectedTab, setSelectedTab, course }) => {
  return (
    <Card>
      <Form>
        <Form.Item>
          <span style={{ color: Theme.custom.red }}>* </span>
          Course Overview
          <ReactMde
            value={course.overview}
            onChange={(value) => onChange('overview', value)}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            minEditorHeight={500}
            minPreviewHeight={500}
          />
        </Form.Item>
      </Form>
    </Card>
  )
}

export default Overview
