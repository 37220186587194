import Enums from '../utils/enums'

const handleCreateNewCourse = (dataTemplate, getAllCourses) => {
  return {
    type: Enums.ACTIONS.NEW_COURSE,
    payload: { dataTemplate, getAllCourses }
  }
}

const handleEditCourse = (courseData, getAllCourses) => {
  return {
    type: Enums.ACTIONS.EDIT_COURSE,
    payload: { courseData, getAllCourses }
  }
}

const handleOpenCourse = (courseData, getAllCourses) => {
  return {
    type: Enums.ACTIONS.OPEN_COURSE,
    payload: { courseData, getAllCourses }
  }
}

const handleDeleteCourse = (index) => {
  return {
    type: Enums.ACTIONS.DELETE_COURSE,
    payload: index
  }
}

const handleSetCourses = (courseData) => {
  return {
    type: Enums.ACTIONS.SET_COURSE,
    payload: { courses: courseData }
  }
}

const handleCourseUpdate = (courseData) => {
  return {
    type: Enums.ACTIONS.UPDATE_COURSE,
    payload: courseData
  }
}

const resetApp = () => {
  return {
    type: Enums.ACTIONS.RESET_APP
  }
}

export {
  handleCreateNewCourse,
  handleEditCourse,
  handleOpenCourse,
  handleDeleteCourse,
  handleSetCourses,
  handleCourseUpdate,
  resetApp
}
