import React, { useEffect, useReducer } from 'react'
import ReactDragListView from 'react-drag-listview'
import { Card, Table, Button, Modal, Form, Input, Select, Tabs, message, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import Theme from '../../../styles/theme'
import { lessonDataTemplate } from 'utils/data-templates'

import ReactMde from 'react-mde'
import * as Showdown from 'showdown'
import 'react-mde/lib/styles/css/react-mde-all.css'
import Enums from 'utils/enums'
import { v1 } from 'agilite-utils/uuid'
import { getCourseLessons, getLessonContent } from 'courses/controller'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  parseImgDimensions: true
})

const Lessons = ({ levels, handleChange, course }) => {
  const [form] = Form.useForm()

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    formOpen: false,
    lesson: null,
    selectedTab: 'write',
    lessons: [],
    lessonIndex: null,
    content: '',
    loading: false
  })

  useEffect(() => {
    handleGetCourseLessons()
    // eslint-disable-next-line
  }, [])

  const handleGetCourseLessons = async () => {
    try {
      setState({ loading: true })
      const lessons = await getCourseLessons(course.id)
      setState({ lessons })
    } catch (e) {
      message.error(e.message)
    }
    setState({ loading: false })
  }

  const handleGetLessonContent = async (lesson) => {
    let result = null

    try {
      if (lesson.content) {
        result = await getLessonContent(course.id, lesson.content)
        setState({ content: result.content })
      }
    } catch (e) {
      console.error(e)
      message.error(e.message)
    }
  }

  const handleCreateNewLesson = () => {
    form.setFieldsValue(lessonDataTemplate())
    setState({ formOpen: true, lesson: lessonDataTemplate(), lessonIndex: -1 })
  }

  const handleLessonOpen = async (lessonObject, index) => {
    form.setFieldsValue(lessonObject)
    setState({ formOpen: true, lesson: lessonObject, lessonIndex: index })

    if (!lessonObject.contentData) {
      handleGetLessonContent(lessonObject)
    } else {
      setState({ content: lessonObject.contentData })
    }
  }

  const submitLesson = () => {
    const tmpData = JSON.parse(JSON.stringify(state.lessons))
    const entry = { ...form.getFieldsValue(), contentData: state.content }
    let errorMessage = ''

    // Validate Lesson Data
    if (!entry.title) {
      errorMessage = Enums.MESSAGES.REQUIRED_LESSON_TITLE
    } else if (!entry.description) {
      errorMessage = Enums.MESSAGES.REQUIRED_LESSON_DESCRIPTION
    } else if (!entry.levelId) {
      errorMessage = Enums.MESSAGES.REQUIRED_LESSON_LEVEL_ID
    } else if (!entry.skillPoints) {
      errorMessage = Enums.MESSAGES.REQUIRED_LESSON_SKILL_POINTS
    } else if (!entry.skillCoins) {
      errorMessage = Enums.MESSAGES.REQUIRED_LESSON_SKILL_COINTS
    } else if (!entry.contentData && !entry.videoUrl && !entry.audioUrl) {
      errorMessage = Enums.MESSAGES.REQUIRED_LESSON_CONTENT
    }

    if (errorMessage) return message.error(errorMessage)

    if (state.lessonIndex === -1) {
      entry.id = Date.now().toString()
      tmpData.push(entry)
    } else {
      entry.id = state.lesson.id
      tmpData.splice(state.lessonIndex, 1, entry)
    }

    handleChange('lessons', tmpData)
    setState({ lessons: tmpData, lesson: lessonDataTemplate(), content: '', formOpen: false })
  }

  const deleteLesson = (index) => {
    const tmpData = JSON.parse(JSON.stringify(state.lessons))
    tmpData.splice(index, 1)

    handleChange('lessons', tmpData)
    setState({ lessons: tmpData, lesson: lessonDataTemplate(), formOpen: false })
  }

  useEffect(() => {
    form.setFieldsValue(state.lesson)
  }, [state.lesson, form])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...state.lessons]
      const item = data.splice(fromIndex, 1)[0]
      data.splice(toIndex, 0, item)
      handleChange('lessons', data)
    },
    handleSelector: '.drag-handle'
  }

  return (
    <Card
      size='small'
      extra={
        <Button type='primary' onClick={handleCreateNewLesson}>
          Create Lesson
        </Button>
      }
    >
      <ReactDragListView {...dragProps}>
        <Table
          loading={state.loading}
          rowClassName='drag-handle'
          dataSource={state.lessons}
          columns={[
            {
              title: 'Title',
              key: 'title',
              dataIndex: 'title',
              width: '30%',
              render: (value, record, index) => {
                return (
                  // eslint-disable-next-line
                  <a onClick={() => handleLessonOpen(record, index)}>
                    Lesson {index + 1} - {value}
                  </a>
                )
              }
            },
            {
              title: 'Description',
              key: 'description',
              dataIndex: 'description',
              width: '40%'
            },
            {
              title: 'Level',
              key: 'level',
              dataIndex: 'levelId',
              width: '20%',
              render: (value) => {
                const levelIndex = levels.findIndex((entry) => entry.id === value)

                if (levelIndex !== -1) {
                  return (
                    <>
                      Level {levelIndex + 1} - {levels[levelIndex].label}
                    </>
                  )
                } else {
                  return 'Loading...'
                }
              }
            },
            {
              title: 'Actions',
              key: 'actions',
              width: '10%',
              render: (value, record, index) => {
                // eslint-disable-next-line
                return (
                  <>
                    <Popconfirm
                      title='Are you sure you want to Delete this Lesson?'
                      onConfirm={() => deleteLesson(index)}
                      okText='Yes'
                    >
                      <DeleteOutlined style={{ color: Theme.twitterBootstrap.danger }} />
                    </Popconfirm>
                  </>
                )
              }
            }
          ]}
          size='small'
          bordered
          pagination={false}
          rowKey={() => v1()}
        />
      </ReactDragListView>
      <Modal
        title={state.lesson ? 'Edit Lesson' : 'New Lesson'}
        onCancel={() => setState({ formOpen: false, content: '' })}
        onOk={submitLesson}
        closable={false}
        visible={state.formOpen}
        okText='Submit'
        destroyOnClose
        width={'40%'}
        getContainer={false}
      >
        <Form
          form={form}
          name='basic'
          initialValues={state.lesson}
          wrapperCol={{ sm: 12, md: 12, lg: 14, xl: 16, xxl: 18 }}
          labelCol={{ sm: 12, md: 12, lg: 10, xl: 8, xxl: 6 }}
          labelAlign='left'
        >
          <Tabs defaultActiveKey='general'>
            <Tabs.TabPane key='general' tab='General'>
              <Form.Item required label='Level' name='levelId'>
                <Select>
                  <Select.Option value=''>- Select a Level -</Select.Option>
                  {levels.map((level, index) => {
                    return (
                      <Select.Option key={index} value={level.id}>
                        Level {index + 1} - {level.label}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item required label='Title' name='title'>
                <Input />
              </Form.Item>
              <Form.Item required label='Description' name='description'>
                <Input.TextArea rows={3} />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane key='details' tab='Details'>
              <Form.Item label='Video URL' name='videoUrl'>
                <Input />
              </Form.Item>
              <Form.Item label='Audio URL' name='audioUrl'>
                <Input />
              </Form.Item>
              <Form.Item required label='Claimable Skill Points' name='skillPoints'>
                <Input type='number' />
              </Form.Item>
              <Form.Item required label='Claimable Skill Coins' name='skillCoins'>
                <Input type='number' />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane key='content' tab='Lesson Content'>
              <Form.Item wrapperCol={{ span: 24 }}>
                <ReactMde
                  value={state.content || ''}
                  onChange={(content) => setState({ content })}
                  selectedTab={state.selectedTab}
                  onTabChange={(selectedTab) => setState({ selectedTab })}
                  generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
                  minEditorHeight={500}
                  minPreviewHeight={500}
                />
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Modal>
    </Card>
  )
}

export default Lessons
