import React from 'react'
import { Spin } from 'antd'

import styles from './style.module.sass'

const ButtonSpinner = ({ spinning }) => {
  return <Spin spinning={spinning} className={styles.spin} />
}

export default ButtonSpinner
