import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'

import Enums from 'utils/enums'
import { handleSignOut } from 'core/core-actions'
import { resetApp } from 'courses/course-actions'
import { signOut } from 'firebase/auth'

import { auth } from '-new/lib/firebase'

const CustomMenu = () => {
  const dispatch = useDispatch()
  const app = useSelector((state) => state.app)

  return (
    <>
      <UserOutlined style={{ marginRight: 10 }} />
      <span style={{ marginRight: 30, fontSize: 16 }}>
        {app.user.data.username ? app.user.data.username : 'Anonymous'}
      </span>
      <Popconfirm
        title={Enums.MESSAGES.SIGN_OUT_CONFIRM}
        okText='Yes'
        cancelText='No'
        onConfirm={async () => {
          dispatch(handleSignOut())
          dispatch(resetApp())
          await signOut(auth)
        }}
      >
        <Tooltip title='Sign Out'>
          <LogoutOutlined />
        </Tooltip>
      </Popconfirm>
    </>
  )
}

export default CustomMenu
