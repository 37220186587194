import React, { useEffect, useReducer } from 'react'
import { Card, Form, Input, Select, Spin } from 'antd'
import CourseUploads from '../course-uploads'
import Enums from '../../../utils/enums'

const General = ({ course, courseImage, onChange, onChangeImage, tagData, categoryData }) => {
  const [form] = Form.useForm()

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    tags: []
  })

  useEffect(() => {
    let tags = null

    try {
      tags = course.tags || []

      form.setFieldsValue(course)
      setState({ tags })
    } catch (e) {
      console.error(e)
    }
  }, [course, form])

  return (
    <Card>
      <Form name='basic' form={form} wrapperCol={{ span: 20 }} labelCol={{ span: 3 }} labelAlign='left'>
        <Form.Item required label='Title' name='title'>
          <Input
            onChange={(e) => onChange('title', e.target.value)}
            placeholder={`${Enums.PLACEHOLDERS.COURSE_TITLE}`}
          />
        </Form.Item>
        <Form.Item required label='Description' name='description'>
          <Input.TextArea
            onChange={(e) => onChange('description', e.target.value)}
            placeholder={`${Enums.PLACEHOLDERS.COURSE_DESCRRIPTION}`}
            rows={3}
          />
        </Form.Item>
        <Form.Item label='Tags' name='tags'>
          <Select
            mode='tags'
            placeholder={`Add up to ${Enums.COURSE_VALUES.TAG_LIMIT} optional Tags`}
            onChange={(values) => tagData.handleTagChange(values)}
            open={false}
            value={state.tags}
          >
            {state.tags.map((item) => {
              return <Select.Option key={item}>{item}</Select.Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item label='Categories' name='categories'>
          <Select
            placeholder={`Add ${Enums.COURSE_VALUES.CATEGORY_LIMIT} optional Categories`}
            onChange={(value, e) => {
              onChange('categories', e.key)
            }}
            maxTagCount={Enums.COURSE_VALUES.CATEGORY_LIMIT}
            notFoundContent={categoryData.categoriesLoading ? <Spin size='small' /> : null}
            onClick={categoryData.handleGetCategories}
          >
            <Select.Option value=''>- Please select a Category -</Select.Option>
            {categoryData.categories.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.category}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </Form>
      <center>
        <CourseUploads onChangeImage={onChangeImage} image={courseImage} />
      </center>
    </Card>
  )
}

export default General
