import { initializeApp } from 'firebase/app'
import { getFirestore, doc, setDoc, getDoc, updateDoc, connectFirestoreEmulator } from 'firebase/firestore'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import Enums from './enums'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyAIe0ud3U9fkZYzLrfRHLm2a7Cto1-hQrc',
  authDomain: 'metability-37dbb.firebaseapp.com',
  projectId: 'metability-37dbb',
  storageBucket: 'metability-37dbb.appspot.com',
  messagingSenderId: '199583059562',
  appId: '1:199583059562:web:0864b769339fdc1d794413'
}

const isProd = process.env.REACT_APP_ENVIRONMENT === Enums.environment.FIREBASE_PROD
initializeApp(config)
const db = getFirestore()
const auth = getAuth()

if (!isProd) {
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectAuthEmulator(auth, 'http://localhost:9099')
}

// FIREBASE FUNCTIONS
const fetchUserData = (uid) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null

      try {
        const docRef = doc(db, 'userData', uid)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) result = docSnap.data()
        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const updateUserProfilePic = (uid, profilePicId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const docRef = doc(db, 'userData', uid)
        await updateDoc(docRef, { profilePicId })

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const updateUserData = (uid, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const docRef = doc(db, 'userData', uid)
        await updateDoc(docRef, data)

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const addUserData = (uid, userData) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        await setDoc(doc(db, 'userData', uid), userData)
        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export { auth, fetchUserData, addUserData, updateUserProfilePic, updateUserData }
