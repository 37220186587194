import React, { useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isSignInWithEmailLink, onAuthStateChanged, signInWithEmailLink } from 'firebase/auth'

import LoadingIndicator from '-new/components/LoadingIndicator'
import LoginUser from '-new/components/LoginUser'

import Enums from 'utils/enums'
import { getUserTemplate } from 'utils/data-templates'
import { handleSignIn } from 'core/core-actions'
import { auth, fetchUserData, addUserData } from '-new/lib/firebase'

const Wrapper = () => {
  const dispatch = useDispatch()
  const reduxUser = useSelector((state) => state.app.user)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    emailSignInComplete: false
  })

  // Operations to run on Start of App
  useEffect(() => {
    async function init() {
      let userObject = null
      let userData = null

      try {
        // Check if User Auth state has changed or is complete
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            // Check if User State exists
            userData = await fetchUserData(user.uid)

            if (!userData) {
              userData = getUserTemplate()
              await addUserData(user.uid, userData)
            }

            dispatch(handleSignIn())
            userObject = { uid: user.uid, authComplete: true, data: userData }
          } else {
            userObject = { uid: '', authComplete: true, data: {} }
          }

          dispatch({ type: Enums.ACTIONS.SET_USER, payload: userObject })
        })

        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem('emailForSignIn')
          if (!email) email = window.prompt('Please provide your email for confirmation')

          await signInWithEmailLink(auth, email, window.location.href)

          window.localStorage.removeItem('emailForSignIn')
          window.location.replace(window.location.origin)
        } else {
          window.localStorage.removeItem('emailForSignIn')
          setState({ emailSignInComplete: true })
        }
      } catch (e) {
        console.error(e)
      }
    }

    init()

    // eslint-disable-next-line
  }, [])

  return (
    <div>
      {!reduxUser.authComplete || !state.emailSignInComplete ? <LoadingIndicator /> : undefined}
      {reduxUser.authComplete && state.emailSignInComplete && !reduxUser.uid ? <LoginUser /> : undefined}
    </div>
  )
}

export default Wrapper
