import React, { useState, useEffect } from 'react'
import { message, Row, Col, Card, Table, Button, Popconfirm, Form, Input } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import { v1 } from 'agilite-utils/uuid'
import Modal from 'antd/lib/modal/Modal'
import SortBy from 'lodash/sortBy'
import Enums from '../../utils/enums'

import { labelValueObjectTemplate } from '../../utils/data-templates'
import Theme from '../../styles/theme'
import { getAllCategories, setCategory, deleteCategory } from 'categories/controller'

const Categories = () => {
  const [tableData, setTableData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [cTableLoading, setCTableLoading] = useState(false)
  const [modalExistingOpen, setModalExistingOpen] = useState(false)
  const [valueObj, setValueObj] = useState(labelValueObjectTemplate())

  const createCategory = () => {
    valueObj.category.trim()
    if (!valueObj.category) {
      return message.error('Please provide a Category Name')
    } else {
      handleCategoryAdd(valueObj.category)
      setModalOpen(false)
    }
  }

  const updateCategory = () => {
    valueObj.category.trim()

    if (!valueObj.category) {
      return message.error('Please provide a Category Name')
    } else {
      handleUpdateCategories(valueObj.category, valueObj.id)
      setModalExistingOpen(false)
    }
  }

  const columns = [
    {
      title: 'Category Name',
      key: 'name',
      render: (value, record) => {
        // eslint-disable-next-line
        return <a onClick={() => handleCategoryEdit(record)}>{record.category}</a>
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '5%',
      render: (text, record) => {
        return (
          <Popconfirm
            title={`${Enums.MESSAGES.DELETE_CONFIRM} category?`}
            okText='Yes'
            onConfirm={() => handleCategoryDelete(record.id)}
          >
            <DeleteTwoTone twoToneColor={Theme.twitterBootstrap.danger} />
          </Popconfirm>
        )
      }
    }
  ]

  useEffect(() => {
    handleGetCategories()
  }, [])

  const handleGetCategories = async () => {
    let result = null

    try {
      setCTableLoading(true)
      result = await getAllCategories()
      setTableData(result)
      setCTableLoading(false)
    } catch (e) {
      message.error(e.message)
    }
  }

  const handleCategoryDelete = async (id) => {
    try {
      await deleteCategory(id)
      handleGetCategories()
    } catch (e) {
      message.error(e.message)
    }
  }

  const handleCategoryAdd = async (category) => {
    try {
      await setCategory(category)
      message.success('Category created successfully')
      setValueObj(labelValueObjectTemplate())
      handleGetCategories()
    } catch (e) {
      message.error(e.message)
    }
  }

  const handleCategoryEdit = (record) => {
    setValueObj(record)
    setModalExistingOpen(true)
  }

  const handleUpdateCategories = async (category, id) => {
    try {
      await setCategory(category, id)
      message.success('Category updated successfully')
      setValueObj(labelValueObjectTemplate())
      handleGetCategories()
    } catch (e) {
      message.error(e.message)
    }
  }

  const handleCategoryChange = (value) => {
    setValueObj({
      ...valueObj,
      category: value.trim()
    })
  }

  return (
    <Row justify='center'>
      <Col span={14}>
        <Card
          extra={
            <Button type='primary' onClick={() => setModalOpen(true)}>
              Add
            </Button>
          }
          type='inner'
          size='small'
        >
          <Table
            columns={columns}
            dataSource={SortBy(tableData)}
            bordered
            loading={cTableLoading}
            pagination={false}
            rowKey={() => v1()}
          />
        </Card>
      </Col>
      <Modal
        destroyOnClose
        centered
        visible={modalOpen}
        title='New Category'
        closable={false}
        maskClosable={false}
        footer={null}
      >
        <Form>
          <Form.Item>
            Category Name
            <Input onChange={(e) => handleCategoryChange(e.target.value)} />
          </Form.Item>
        </Form>
        <Form.Item>
          <Popconfirm title='Are you sure you want to Create this Category?' onConfirm={createCategory} okText='Yes'>
            <Button type='primary' style={{ float: 'right', textAlign: 'right', margin: 10 }}>
              Submit
            </Button>
          </Popconfirm>
          <Popconfirm
            title='Are you sure you want discard all changes?'
            onConfirm={() => setModalOpen(false)}
            okText='Yes'
          >
            <Button type='secondary' style={{ float: 'right', textAlign: 'right', margin: 10 }}>
              Cancel
            </Button>
          </Popconfirm>
        </Form.Item>
      </Modal>
      <Modal
        destroyOnClose
        centered
        visible={modalExistingOpen}
        title='Edit Category'
        closable={false}
        maskClosable={false}
        footer={null}
      >
        <Form>
          <Form.Item>
            Category Name
            <Input defaultValue={valueObj.category} onChange={(e) => handleCategoryChange(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Popconfirm title='Are you sure you want to Update this Category?' onConfirm={updateCategory} okText='Yes'>
              <Button type='primary' style={{ float: 'right', textAlign: 'right', margin: 10 }}>
                Submit
              </Button>
            </Popconfirm>
            <Popconfirm
              title='Are you sure you want discard all changes?'
              onConfirm={() => setModalExistingOpen(false)}
              okText='Yes'
            >
              <Button type='secondary' style={{ float: 'right', textAlign: 'right', margin: 10 }}>
                Cancel
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  )
}

export default Categories
