import React from 'react'
import { HomeOutlined, SettingOutlined } from '@ant-design/icons'

// Redux
import store from '../core/core-store'
import {
  handleMenuItemClick,
  handleLeftMenuClose,
  handleLeftMenuOpen,
  handleTabChange,
  handleTabClose
} from '../core/core-actions'

// Components
import Wrapper from '-new/-app/components/Wrapper'

// Utilities & Enums
import Enums from '../utils/enums'

const state = {
  rootContent: Wrapper,
  theme: {},
  leftMenu: {
    leftMenuEnabled: false,
    leftMenuTitle: Enums.TITLES.PORTAL_MENU,
    visible: false,
    expandedMenuItems: [Enums.KEYS.ADMINISTRATION],
    menuItems: [
      {
        key: Enums.KEYS.COURSES,
        title: (
          <>
            <HomeOutlined style={{ marginRight: 5 }} /> {Enums.TITLES.COURSES}
          </>
        ),
        children: []
      },
      {
        key: Enums.KEYS.ADMINISTRATION,
        title: (
          <>
            <SettingOutlined style={{ marginRight: 5 }} /> {Enums.TITLES.ADMINISTRATION}
          </>
        ),
        children: [
          {
            key: Enums.KEYS.CATEGORIES,
            title: (
              <>
                <SettingOutlined style={{ marginRight: 5 }} /> {Enums.TITLES.CATEGORIES}
              </>
            ),
            children: []
          }
        ]
      }
    ],
    onLeftMenuItemClick: (e) => store.dispatch(handleMenuItemClick(e)),
    onLeftMenuClose: (e) => store.dispatch(handleLeftMenuClose(e)),
    onLeftMenuOpen: (e) => store.dispatch(handleLeftMenuOpen(e))
  },
  rightMenu: {
    rightMenuEnabled: false,
    rightMenutitle: '',
    visible: false,
    menuItems: [],
    expandedMenuItems: []
  },
  tabNavigation: {
    enabled: false,
    activeKey: Enums.KEYS.COURSES,
    animated: false,
    onTabChange: (key) => store.dispatch(handleTabChange(key)),
    onTabClose: (key, action) => store.dispatch(handleTabClose(key, action)),
    rootTabKey: Enums.KEYS.COURSES,
    rootTabTitle: Enums.TITLES.COURSES,
    tabs: []
  },
  toolbar: {
    enabled: true,
    title: process.env.REACT_APP_NAME,
    customMenus: {
      content: null
    }
  }
}

export default state
